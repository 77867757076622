.auth {
  position: relative;
}

.auth.is-send {
  display: flex;
  height: 100%;
}

.auth.is-send .form {
  display: none;
}

.auth__message {
  display: none;
  margin: auto;

  text-align: center;

  .auth.is-success &[data-message-success] {
    display: block;
  }

  .auth.is-error &[data-message-error] {
    display: block;
  }
}

// Preloader
// is-sending

.auth__preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;

  display: none;
  width: 100px;
  height: 100px;

  color: $color-main-dark;

  transform: translate(-50%, -50%);

  fill: $color-main-dark;
}

.is-sending .auth__preloader {
  display: block;
}

.auth__preloader svg {
  animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}
