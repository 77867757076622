.logo {
  display: block;
  width: 85px;
  height: 85px;

  color: #000000;
}

// .logo svg {
//   fill: currentColor;
// }

.logo:hover,
.logo:focus {
  fill: $color-smalt;
}

.logo:active {
  fill: #727272;
}

.logo svg {
  width: 100%;
  height: 100%;
}
