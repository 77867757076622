.tabs {
  padding: 20px;

  color: #ffffff;

  background-color: $color-main-dark;
  border-radius: $border-radius-main;
}

.tabs__nav {
  display: flex;
  justify-content: start;
  margin: 0;
  padding-bottom: 10px;
  overflow: hidden;
  overflow-x: auto;

  list-style-type: none;

  scrollbar-color: $color-main-light rgba(#ffffff, 0.5);
  scrollbar-width: thin;

  .no-js & {
    display: none;
  }

  &::-webkit-scrollbar {
    // width: 5px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(#ffffff, 0.5);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    width: 30px;
    // height: 30px;

    background-color: $color-main-light;
    border-radius: 20px;
  }
}

.tabs__btn {
  display: inline-block;
  flex-shrink: 0;
  max-width: 190px;
  padding: 10px;

  font-weight: 700;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  background-color: transparent;
  border: 1px solid transparent;
  border-radius: $border-radius-main;
  cursor: pointer;
}

.tabs__btn.is-active {
  color: #000000;

  background-color: $color-main-light;
  cursor: default;
}

.tabs__btn:not(.is-active):hover,
.tabs__btn:not(.is-active):focus {
  color: #000000;

  background-color: $color-hover;
}

.tabs__pane {
  padding: 20px;

  .no-js &[data-tabs="element"] {
    position: static;

    visibility: visible;
    opacity: 1;
  }
}

.tabs__pane h2,
.tabs__pane h3,
.tabs__pane h4 {
  margin: 0 0 20px;
}

.tabs__pane h3 {
  display: none;

  .no-js & {
    display: block;
  }
}

.tabs__title {
  display: none;

  @media (max-width: 425px) {
    display: inline-block;
  }

  .no-js & {
    display: inline-block;
  }
}
