// @mixin font($font_name, $file_name, $weight, $style) {
//   font-style: #{$style};
//   font-weight: #{$weight};
//   font-family: $font_name;

//   font-display: swap;
//   src: url("../fonts/#{$file_name}.woff2") format("woff2"),
//   url("../fonts/#{$file_name}.woff") format("woff");
// }

// @mixin fontFolder($font_name, $file__direct, $file_name, $weight, $style) {
//   font-style: #{$style};
//   font-weight: #{$weight};
//   font-family: $font_name;

//   font-display: swap;
//   src: url("../fonts/#{$file__direct}/#{$file_name}.woff2") format("woff2"),
//     url("../fonts/#{$file__direct}/#{$file_name}.woff") format("woff");
// }

// Так как в сборке в css настроены пути относительно файлов scss,
// то в пути нужно прописать "../../img" или "../../fonts"

// Kodchasan
@font-face {
  @include fontFolder("Kodchasan", kodchasan, Kodchasan-Regular, 400, normal);
}

@font-face {
  @include fontFolder("Kodchasan", kodchasan, Kodchasan-Italic, 400, italic);
}

@font-face {
  @include fontFolder("Kodchasan", kodchasan, Kodchasan-Bold, 700, normal);
}

@font-face {
  @include fontFolder("Kodchasan", kodchasan, Kodchasan-BoldItalic, 700, italic);
}

// Pacifico
@font-face {
  @include fontFolder("Pacifico", pacifico, Pacifico-Regular, 400, normal);
}

// Proxima Nova

@font-face {
  @include fontFolder("Proxima Nova", proxima-nova, ProximaNova-Light, 300, normal);
}

@font-face {
  @include fontFolder("Proxima Nova", proxima-nova, ProximaNova-Regular, 400, normal);
}

@font-face {
  @include fontFolder("Proxima Nova", proxima-nova, ProximaNova-Semibold, 600, normal);
}

@font-face {
  @include fontFolder("Proxima Nova", proxima-nova, ProximaNova-Extrabld, 800, normal);
}

@font-face {
  @include fontFolder("Proxima Nova", proxima-nova, ProximaNova-Bold, 700, normal);
}

@font-face {
  @include fontFolder("Proxima Nova", proxima-nova, ProximaNova-Black, 900, normal);
}
