.form__field {
  display: block;
  width: 100%;
  padding: 10px;

  font-size: 18px;
  line-height: 25px;
  font-family: "Kodchasan", "Arial", sans-serif;
  color: $color-main-dark;
  // background-color: #eeeeee;

  background-color: #e8f0fe;
  border: none;
  border-bottom: 2px solid rgba(1, 20, 58, 0.4);

  @include hover-focus {
    background-color: transparent;
    border-color: $color-smalt;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1000px #e8f0fe;
    // transition: transition: background-color 600000s 0s, color 600000s 0s;

    -webkit-text-fill-color: $color-default-black;
  }

  // border-radius: $border-radius-main;
}

.form__group--agreement {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  grid-column: 1/-1;
  justify-content: start;
  align-items: center;
}

.form__group--agreement input[type="checkbox"] {
  position: relative;

  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;

  border: 2px solid rgba(1, 20, 58, 0.4);

  appearance: none;

  &:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    width: 15px;
    height: 15px;

    background-color: $color-main-dark;
    transform: translate(-50%, -50%);

    mask-image: url("../../img/svg/icon-check.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 10px 10px;
  }
}

.form__group--agreement label {
  font-size: 12px;
  line-height: 15px;
  text-align: left;
}

.form__submit {
  grid-column: 1/-1;
}

.form__group.is-invalid input,
.form__group.is-invalid input[type="checkbox"],
.form__group.is-invalid textarea {
  border-color: $color-error;
}

.form__group.is-valid input,
.form__group.is-valid input[type="checkbox"],
.form__group.is-valid textarea {
  border-color: $color-success;
}
