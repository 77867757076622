.button {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding: 15px;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  font-family: $ff-kodchasan;
  text-align: center;
  color: $color-default-white;
  text-transform: uppercase;

  background-color: $color-smalt;
  border: 2px solid $color-smalt;
  border-radius: $border-radius-main;
  cursor: pointer;

  transition: opacity $trans-default;

  &:hover,
  &:focus {
    // color: $color-hover;
    background-color: $color-neon-blue;
    border-color: $color-neon-blue;
    outline: none;
  }

  &--red {
    background-color: $color-error;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }
}
