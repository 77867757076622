.product {
  padding: 70px 0;
}

.product--page {
  @media (max-width: 767px) {
    padding-top: 0;
  }
}

.product--page .title-page {
  text-decoration: none;
}

.product__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
  }
}

.product__item:nth-child(2n) .product__description {
  order: -1;

  @media (max-width: 1024px) {
    order: 1;
  }
}

.product__item h3 {
  margin-top: 0;

  font-size: 26px;
  line-height: 28px;
  text-shadow: 1px 1px 1px #ffffff;

  @media (max-width: 525px) {
    text-align: center;
  }
}

.product__item-card {
  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.product__img-container {
  position: relative;

  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 400px;
  height: 400px;

  perspective: 1000px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 525px) {
    width: 250px;
    height: 250px;
  }
}

.product__img {
  max-width: 400px;
  max-height: 400px;
}

.product__img img {
  display: block;
  width: 100%;
  height: 100%;
}

.product--home .product__img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 10px;
  outline: none;
  backface-visibility: hidden;

  transition: 1s;
}

.product__img--back {
  transform: rotateY(180deg);
}

.product__img-container:hover .product__img--front {
  @media (min-width: 1024px) {
    transform: rotateY(180deg);
  }
}

.product__img-container:hover .product__img--back {
  @media (min-width: 1024px) {
    transform: rotateY(360deg);
  }
}

.product__description {
  padding: 20px 40px;

  @media (max-width: 525px) {
    padding: 20px 0;
  }
}

.product__btn {
  margin: 15px auto 0;
}

.product__content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px 50px;
  align-items: start;
  margin-bottom: 50px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    gap: 50px;
    justify-content: center;
  }
}
