.title-page {
  margin: 0 0 40px;

  font-size: 60px;
  line-height: 70px;
  font-family: $ff-pacifico;
  text-align: center;
  text-decoration: 4px;

  text-decoration-color: inherit;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 14px;
}
