.menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 30;

  padding: 20px 0;

  background-color: #c0e4fe;
  box-shadow: 1px 1px 10px #ffffff, 1px 1px 10px #000000;

  @media (max-width: 767px) {
    padding: 0;
  }
}

.menu__logo {
  position: absolute;
  top: 50%;
  left: 10px;

  transform: translateY(-50%);

  @media (max-width: 767px) {
    top: 10px;

    width: 40px;
    height: 40px;

    transform: none;
  }
}

.header--home .menu__logo {
  display: none;
}

.menu__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    display: none;
    overflow: auto;

    background-color: $color-main-light;
  }
}

.menu__item {
  position: relative;
}

.menu__link {
  display: inline-block;
  padding: 10px;

  font-size: 30px;
  line-height: 32px;
  font-family: $ff-pacifico;
  color: #000000;
  text-decoration: none;
  text-shadow: 1px 1px 0 #ffffff;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }

  &:focus,
  &:hover {
    color: $color-hover;
    text-shadow: 1px 1px 0 $color-main-dark;

    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.menu__link--drop {
  position: relative;

  padding-right: 35px;

  & svg {
    content: "";
    position: absolute;
    right: 8px;
    bottom: 14px;

    width: 15px;
    height: 15px;

    transition: $trans-default;

    fill: currentColor;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.menu__sub-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;

  display: none;
  width: 350px;
  padding: 20px;

  background-color: $color-main-light;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px $color-main-dark;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 10px;

    box-shadow: none;
  }
}

.menu__button {
  position: absolute;
  top: 5px;
  right: 16px;
  z-index: 20;

  display: none;
  width: 40px;
  height: 40px;

  color: inherit;

  background-color: transparent;
  border: none;

  // border: 2px solid #000000;

  @media (max-width: 767px) {
    display: block;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.menu__button span {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 30px;
  height: 4px;

  background-color: $color-default-black;
  border-radius: $border-radius-main;
  transform: translate(-50%, -50%);

  transition: $trans-default;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;

    width: 30px;
    height: 4px;

    background-color: $color-default-black;
    border-radius: $border-radius-main;
    transform: translateX(-50%);

    transition: $trans-default;
  }

  &::before {
    top: 8px;
  }

  &::after {
    bottom: 8px;
  }
}

// .nav--open .menu__button {
//   right: 45px;
// }

.menu--open .menu__button span {
  transform: translate(-50%, -50%) rotate(45deg);

  transition: $trans-default;

  &::before {
    top: 0;

    transform: translateX(-50%) rotate(-90deg);

    transition: $trans-default;
  }

  &::after {
    background-color: transparent;
    border: none;

    transition: $trans-default;
  }
}

.menu__item--drop:hover .menu__link--drop,
.menu__item--drop:focus-within .menu__link--drop {
  @media (min-width: 581px) {
    color: $color-hover;
    text-shadow: 1px 1px 0 $color-main-dark;
  }
}

.menu__item--drop:hover svg,
.menu__item--drop:focus-within svg {
  transform: rotate(180deg);

  transition: $trans-default;
}

.menu__item--drop:hover .menu__sub-menu,
.menu__item--drop:focus-within .menu__sub-menu {
  display: block;
}

.menu--open .menu__list {
  @media (max-width: 767px) {
    display: block;
    padding: 50px 20px;
  }
}

.menu--open .menu__sub-menu {
  @media (max-width: 767px) {
    position: static;

    display: block;

    transform: none;
  }
}
