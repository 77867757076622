// Colors
// ---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/334482

$color-smalt: #043393;
$color-error: #ff1553;
$color-success: #52bf09;
$color-main-light: #bee2fc;
$color-main-dark: #01143a;
$color-hover: #e6dc2d;
$color-neon-blue: #2c39f2;
$color-torch-red: #ff1553;

// Gradient

$black-to-right: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);

$border-radius-main: 3px;
$border-size: 3px;

// Typography
// ---------------------------------

// переменная используемая в html для подключения скейлинга
$fz-default: 16px;
$lh-default: 25px;

// у некоторых шрифтов в ios возникают проблемы с символом рубля
// https://www.artlebedev.ru/kovodstvo/sections/159/#13
$ff-proxima: "Proxima Nova", "Verdana", sans-serif;
$ff-kodchasan: "Kodchasan", "Verdana", sans-serif;
$ff-pacifico: "Pacifico", "Verdana", sans-serif;
$ff-rouble: "Rouble", "Arial", sans-serif;
$ff-placeholder: "Placeholder", "Arial", sans-serif;

// Animation
// ---------------------------------

$tf-default: ease;
$trans-default: 0.3s $tf-default;

// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600: 0.6s $tf-default;

// Viewports
// ---------------------------------

$vp-320: 320px;
$vp-375: 375px;
$vp-768: 768px;
$vp-1024: 1024px;
$vp-1280: 1280px;
$vp-1440: 1440px;
$vp-1920: 1920px;

// Retina
// ---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
