.slider {
  position: relative;

  padding: 0 35px;

  @media (max-width: 1023px) {
    max-width: 400px;
    margin: 0 auto;
  }

  // @media (max-width: 767px) {
  //   max-width: 280px;
  // }

  @media (max-width: 425px) {
    max-width: 285px;
    padding: 0;
  }
}

.slider__content {
  max-width: 400px;

  border-radius: $border-radius-main;
}

.slider__navigation {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  display: flex;
  justify-content: space-between;

  transform: translateY(-50%);

  @media (max-width: 425px) {
    z-index: 10;
  }
}

.slider__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  color: $color-main-dark;

  background-color: transparent;
  border: none;

  &--prev {
    transform: rotate(90deg);
  }

  &--next {
    transform: rotate(-90deg);
  }

  &:hover,
  &:focus {
    color: $color-hover;
  }

  &:active {
    color: $color-main-light;
  }
}

.slider__button svg {
  width: 100%;
  height: 100%;

  fill: currentColor;
}
