.footer {
  padding: 70px 0;

  color: #d7d7d7;

  background: #01143a;

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
}

.footer__title-page {
  margin-bottom: 0;

  text-shadow: none;
}

.footer__contacts {
  padding: 40px 0;

  text-align: center;

  & p {
    margin: 0 0 20px;
  }

  & a {
    color: inherit;

    &:hover,
    &:focus {
      color: $color-main-light;
      text-decoration: 2px;

      outline: none;

      text-decoration-color: inherit;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-offset: 4px;
    }

    &:active {
      color: $color-neon-blue;
    }
  }
}

.footer__form-container {
  margin-bottom: 50px;
}

.footer__form-feedback {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
}

.footer__form-feedback input,
.footer__form-feedback textarea,
.footer__form-feedback input[type="checkbox"] {
  color: $color-default-white;

  background-color: #01143a;
  border: 2px solid $color-main-light;

  @include hover-focus {
    border-color: $color-neon-blue;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1000px #01143a;
    // transition: background-color 600000s 0s, color 600000s 0s;

    -webkit-text-fill-color: $color-default-white;
  }
}

.footer__form-feedback input[type="checkbox"]:checked::before {
  background-color: #ffffff;
}

// .footer__form-feedback textarea,
.form__group--textarea {
  grid-column: 1 / -1;
}

.footer__submit {
  grid-column: 1 / -1;
}

.footer__copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 700;
}
