.header {
  min-height: 92px;
  // padding-bottom: 40px;
}

.header__container {
  display: flex;
  padding-top: 20vh;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.header--home {
  min-height: 100vh;
  // background-image: url("../../img/header-bg.jpg");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  @include retina-bg("../../img/header-bg", $file_ext: "jpg");

  // @media (max-width: 768px) {
  //   background-position: center right;
  // }
}

.header__descrip {
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
}

.header__descrip span {
  color: $color-hover;
  text-shadow: 1px 1px 1px $color-main-dark;
}

.header__descrip br {
  @media (max-width: 768px) {
    display: none;
  }
}

.header__form {
  width: 100%;
  max-width: 370px;
}

.header__form-logo {
  width: 160px;
  height: 160px;
  margin: 0 auto 50px;

  color: #000000;
}

.header__form-logo svg {
  width: 100%;
  height: 100%;

  fill: currentColor;
}

.header__form-container {
  padding: 5px 30px 30px;

  text-align: center;

  background-color: rgb(232, 240, 254);
  border-radius: $border-radius-main;
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.5);
}

.header__form-feedback {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
