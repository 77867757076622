*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  font-style: normal;
  font-weight: 400;
  font-size: $fz-default;
  line-height: $lh-default;
  font-family: $ff-kodchasan;
  color: $color-default-black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
  // Что бы прокрутка была с отступом нужно блоку добавить свойство  scroll-margin-top: 50px;
  // оптимизация выравнивания шрифта относительно центра строки
  text-rendering: optimizeLegibility;
  // если по прежнему есть проблемы с выравниванием
  // https://transfonter.org/ - включите настройку https://prnt.sc/12rnt6g и переконвертируйте шрифт
}

body {
  position: relative;

  width: 100%;
  min-width: 320px;
  height: 100%;

  background-color: $color-main-light;
  background-image: url("../../img/bg-light.png");

  scroll-behavior: smooth;
}

// Декоративное подчеркивание ссылок
// a {
//   display: inline-block;
//   padding: 4px 0;

//   color: inherit;
//   text-decoration: none;

//   &:hover,
//   &:focus {
//     text-decoration: 2px;

//     outline: none;

//     text-decoration-color: inherit;
//     text-decoration-line: underline;
//     text-decoration-style: solid;
//     text-underline-offset: 4px;
//   }

//   &:active {
//     opacity: 0.6;
//   }
// }

a {
  color: $color-default-black;
  text-decoration: none;
}

img,
video {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;

  border: 0;

  object-fit: cover;
  object-position: center;
}

textarea {
  resize: none;
}

// chrome autofill background removal
// если на проекте у инпутов используются разные цвета фонов\текста -
// удалите это из глобала и используйте локально с нужными цветами
// rgba не подойдет, сконвертируйте цвет в hex без прозрачности
// если в стилях уже используется box-shadow есть другое решение -
// задать к списку транзишенов `background-color 10000000s ease-out`
input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px $color-default-white;

  -webkit-text-fill-color: $color-default-black;
}

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios button \ inputs reset
select,
textarea,
input:matches(
    [type="email"],
    [type="number"],
    [type="password"],
    [type="search"],
    [type="tel"],
    [type="text"],
    [type="url"]
  ) {
  appearance: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}
